import React from 'react'
// import logo from './logo.svg'
import './App.css'

function Colors () {
  return (
    <div className="Colors">
      <div className="color1">
        #fefae0 foob-yellow
      </div>
      <div className="color2">
        #dda15e foob-brown-light
      </div>
      <div className="color3">
        #bc6c25 foob-brown-dark
      </div>
      <div className="color4">
        #606c38 foob-green-light
      </div>
      <div className="color5">
        #283618 foob-green-dark
      </div>
    </div>
  )
}

export default Colors
